import React from 'react';
import Youtube from './Youtube';
import Vidalytics from './Vidalytics';
import Vimeo from './Vimeo';

const TopBanner = props => {
  const { provider } = props;
  switch (provider) {
    case 'vimeo':
      return <Vimeo {...props} />;
    case 'vidalytics':
      return <Vidalytics {...props} />;
    default:
    case 'youtube':
      return <Youtube {...props} />;
  }
};

export default TopBanner;
